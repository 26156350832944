import React from "react";
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from "gatsby";

import "@assets/scss/App.scss";

import Layout from "@components/layout";
import Header from "@components/header/Header";
import BackToolbar from "@components/modules/back-toolbar/";
import RegisterEmployer from "@components/forms/register-employer/";

import ImageTextModule from "@components/modules/image-text/";
import PopularSearch from "@components/popular-search/index";
import Footer from "@components/footer/";
import SEO from "@components/seo";

const EmployersRegisterPage = (props) => {
    const imgUrl = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "Candidate-Refer-A-Candidate-upload-CV.png" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 800) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)
    return(
        <Layout>
            <SEO
                title="Estate Agency Recruitment | Register as Employer"
                description="Are you a PropTech firm or an estate agent with real estate agent jobs to fill? Sign up to Crux Careers, one of the UK’s top property recruitment agencies."
            />
            <Helmet 
                bodyAttributes={{
                    class: 'grey-bg'
                }}
            />
            <Header />
            <BackToolbar
                backLinkLabel="Back"
                showCreateAlert={false}
            />
            <RegisterEmployer
                formClass=" form-dark mb-34 mb-md-51 mb-xl-0"
            />
            <ImageTextModule
                imgUrl={imgUrl.file.childImageSharp.fluid}
                moduleData={
                    [
                        {
                            id: 1,
                            videoUrl: '',
                            Title: '',
                            Text: '',
                            Buttons: [
                                {
                                    id: '123213',
                                    Label: 'Upload Your CV',
                                    externalLink: null,
                                    page: null,
                                    targetLink: '/candidates/upload-your-cv'
                                }
                            ]
                        }
                    ]
                }
            />
            <PopularSearch {...props}
                sectionClass=" section-grey-bg"
            />
            <Footer />
        </Layout>
    )
}

export default EmployersRegisterPage
